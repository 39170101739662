<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-fuel-type' }">{{
            $t("MENU.ITEM.FUELS.FUELS_TYPES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelType"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <v-overlay :value="!firstLoader && isLoadingFuelType">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelType"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_FUEL_TYPE } from "@/modules/fuels-types/store/fuel-type.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true
    };
  },
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$i18n.t('MENU.ITEM.FUELS.FUELS_TYPES'), route: { name: "list-fuel-type" } },
      { title: this.$i18n.t('LABELS.ADD_NEW') }
    ]);
    vm.generateFormOutOfSchemaJson(this.schemaJson);


    vm.$nextTick(function() {
      vm.firstLoader = false;
    });
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingFuelType" ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "fuels-type-Name-input",
            groupId: "subcontractor-type-Name-group",
            required: "required",
            feedback_id: "fuel-type-live-feedback",
            divider: false,
            colSm: "8",
            i18n: {
              label: "MENU.ITEM.FUELS.NAME",
              placeholder: "MENU.ITEM.FUELS.NAME",
              validation: "FORM.VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "Name"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );

      return tmpValidationObject;
  },
  methods: {
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_FUEL_TYPE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-fuel-type",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-fuel-type" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-fuel-type" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
